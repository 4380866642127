import React from "react";
import Skeleton from "react-loading-skeleton";

const Loading = () => {
  return (
    <div className="container mx-auto px-4 py-12 sm:px-6 lg:px-8">
      <Skeleton className="mb-4 h-10" />
      <Skeleton count={5} />
    </div>
  );
};

export default Loading;

import React from "react";

interface PropsWnNavButton {
  idActive: string;
  text: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

const WnNavButton = ({ idActive, text, setActive }: PropsWnNavButton) => {
  return (
    <div
      onClick={() => setActive(text)}
      className={`nav-item nav-link ${idActive === text ? "active" : ""}`}
      id="nav-home-tab"
      style={{ cursor: "pointer" }}
    >
      {text}
    </div>
  );
};

export default WnNavButton;

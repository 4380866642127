"use client";

import { BASE_API } from "@/config/site";
import API from "@/lib/Api";
import React, { useState } from "react";
import { toast } from "sonner";
import "./style.css";

const FormMail = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handlePostData = async () => {
    if (email) {
      try {
        setLoading(true);
        const { data } = await API.post(`${BASE_API}/api/subscribers`, {
          data: {
            name: email.split("@")[0],
            email: email.trim(),
          },
        });
        if (data) {
          toast.success("Đăng ký thành công", {
            description:
              "Từ giờ bạn sẽ nhận được thông báo khi có bài viết mới nhất",
          });
        }
      } catch (error: any) {
        toast.error(
          error?.response.data.error?.message ===
            "This attribute must be unique"
            ? "Email đã có trong hệ thống"
            : error?.response.data.error?.message ===
              "email must be a valid email"
            ? "email phải là email hợp lệ"
            : error?.response.data.error?.message
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="single-box">
      <div className="small-tittle mb-2">
        <h4>Nhận thông báo khi có bài viết mới</h4>
      </div>
      <aside className="single_sidebar_widget search_widget">
        {/* <form action="#"> */}
        <div className="form-group">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control custon-input"
              placeholder="Nhập email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className={`btns ${
                  !email || loading ? "disable cursor-not-allowed" : ""
                }`}
                type="button"
                onClick={handlePostData}
              >
                <i className="fa fa-paper-plane text-white"></i>
                {/* <i className="ti-search"></i> */}
              </button>
            </div>
          </div>
        </div>
        {/* <button
          className={`button rounded-0 primary-bg w-100 btn_1 boxed-btn flex justify-center ${
            !email || loading ? "cursor-not-allowed" : ""
          }`}
          onClick={handlePostData}
          type="button"
        >
          {loading ? <div className="loader"></div> : <>Đăng ký</>}
        </button> */}
        {/* </form> */}
      </aside>
    </div>
  );
};

export default FormMail;

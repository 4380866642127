"use client";

import React, { Suspense, useEffect, useState } from "react";
import TrendingArea from "../ui/trending-area/trending-area";
import LatestArea from "../ui/latest-area/latest-area";
import Weekly2NewsArea from "../ui/weekly2-news-area/weekly2-news-area";
import RecentArticles from "../ui/recent-articles/recent-articles";
import API from "@/lib/Api";
import { BASE_API } from "@/config/site";
import dayjs from "dayjs";
import Weekly3News from "../ui/weekly3-news/weekly3-news";
import Loading from "@/app/loading";

const SiteContent = () => {
  const today = dayjs().endOf("D");
  const twoWeeksAgo = today.subtract(2, "week").startOf("D");

  const todayISOString = today.toISOString();
  const twoWeeksAgoISOString = twoWeeksAgo.toISOString();

  const [dataPost, setdataPost] = useState<any>(null);
  const [mostRecent, setmostRecent] = useState<any>(null);
  const [mostPopular, setmostPopular] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const handleGetDataPost = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        `${BASE_API}/api/posts/?sort[view]=desc&filters[created_date][$gte]=${twoWeeksAgoISOString}&filters[created_date][$lte]=${todayISOString}&populate=*`
      );
      setdataPost(data)
      return data;
    } catch (error) {
      return null;
    }
  };

  const handleGetMostPopular = async () => {
    try {
      const { data } = await API.get(
        `${BASE_API}/api/posts/?sort[view]=desc&ppagination[limit]=10&populate=*`
      );
      setmostPopular(data)
      return data;
    } catch (error) {
      return null;
    }
  };

  const handleGetMostRecent = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        `${BASE_API}/api/posts/?sort[created_date]=desc&pagination[limit]=3&populate=*`
      );
      setmostRecent(data)
      return data;
    } catch (error) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDataPost();
    handleGetMostPopular();
    handleGetMostRecent();
  }, [])

  return (
    <div>
      {loading ? (
        <div className="container">
          <Loading />
        </div>
      ) : (
        <>
          <TrendingArea dataPost={dataPost} />
          <LatestArea mostRecent={mostRecent} />
          <Weekly2NewsArea mostPopular={mostPopular} />
          <div className="h-10 gray-bg"></div>
          <Suspense fallback={<h2>🌀 Loading...</h2>}>
            <RecentArticles />
          </Suspense>
          <div className="h-10 gray-bg"></div>
          <Weekly3News dataPost={dataPost} />
        </>
      )}
    </div>
  );
};

export default SiteContent;

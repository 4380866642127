/* eslint-disable @next/next/no-img-element */
"use client";
import React from "react";
import Slider from "react-slick";
import "./trending-area.css";
import Link from "next/link";
import Image from "next/image";
import { BASE_API } from "@/config/site";
import dayjs from "dayjs";

interface PropsTrending {
  dataPost: any;
}

const TrendingArea = ({ dataPost }: PropsTrending) => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  let dataLeft1 = null;
  let dataLeft2 = null;

  if (dataPost && dataPost.data.length >= 5) {
    dataLeft1 = dataPost.data[3].attributes;
    dataLeft2 = dataPost.data[4].attributes;
  }

  console.log(dataPost, 'dataPost');
  

  return (
    <div className="trending-area fix pt-25 gray-bg">
      <div className="container">
        <div className="trending-main">
          <div className="row">
            <div className="col-lg-8">
              {/* <!-- Trending Top --> */}
              <div className="slider-container">
                <Slider {...settings}>
                  {/* <!-- Single --> */}
                  {dataPost &&
                    dataPost.data.slice(0, 3).map((e: any) => (
                      <div className="single-slider" key={e.id}>
                        <div className="trending-top mb-30">
                          <div className="trend-top-img md:h-[545px]">
                            <Image
                              src={
                                BASE_API +
                                e.attributes.thumbnail.data.attributes.url
                              }
                              alt={e.attributes.title}
                              fill
                              // objectFit="cover"
                              className="z-0"
                            />

                            <div className="trend-top-cap">
                              <span className="bgr animated animatedFadeInUp fadeInUp">
                                {e.attributes.tags.data[0].attributes.title}
                              </span>
                              <h2>
                                <Link
                                  href={`/news/${e.attributes.slug}`}
                                  className="animated animatedFadeInUp fadeInUp"
                                >
                                  {e.attributes.title}
                                </Link>
                              </h2>
                              <p className="animated animatedFadeInUp fadeInUp">
                                by {e.attributes.author} -{" "}
                                {dayjs(e.attributes.created_date).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            {/* <!-- Right content --> */}
            <div className="col-lg-4">
              {/* <!-- Trending Top --> */}
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6">
                  <div className="trending-top mb-30">
                    {dataLeft1 && <div className="trend-top-img md:h-[295px] z-10">
                      <Image
                        src={BASE_API + dataLeft1.thumbnail.data.attributes.url}
                        alt={dataLeft1.title}
                        // layout="fill"
                        fill
                        // objectFit="cover"
                        className="z-0"
                      />
                      <div className="trend-top-cap trend-top-cap2">
                        <span className="bgb">
                          {dataLeft1.tags.data[0].attributes.title}
                        </span>
                        <h2 className="pr-1">
                          <Link href={`/news/${dataLeft1.slug}`}>
                            {dataLeft1.title}
                          </Link>
                        </h2>
                        <p>
                          by {dataLeft1.author} -{" "}
                          {dayjs(dataLeft1.created_date).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </p>
                      </div>
                    </div>}
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-6">
                  <div className="trending-top mb-30">
                    {dataLeft2 && <div className="trend-top-img md:h-[215px] z-10">
                      <Image
                        src={BASE_API + dataLeft2.thumbnail.data.attributes.url}
                        alt={dataLeft2.title}
                        // layout="fill"
                        fill
                        // objectFit="cover"
                        className="z-0"
                      />
                      <div className="trend-top-cap trend-top-cap2">
                        <span className="bgg">
                          {dataLeft2.tags.data[0].attributes.title}
                        </span>
                        <h2>
                          <Link href={`/news/${dataLeft2.slug}`}>
                            {dataLeft2.title}
                          </Link>
                        </h2>
                        <p>
                          by {dataLeft2.author} -{" "}
                          {dayjs(dataLeft2.created_date).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </p>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingArea;

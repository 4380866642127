/* eslint-disable @next/next/no-img-element */
import React from "react";
import "./style.css";
import FormMail from "../form-mail/form-mail";
import Link from "next/link";
import dayjs from "dayjs";
import Image from "next/image";
import { BASE_API } from "@/config/site";

interface PropsTabContentRight {
  dataPost: any;
}

const TabContentRight = ({ dataPost }: PropsTabContentRight) => {
  if (!dataPost) {
    return <></>;
  }

  const { data } = dataPost;
  const firstItem = data[0].attributes;

  return (
    <div className="col-lg-4">
      {/* <!-- Most Recent Area --> */}
      <div className="most-recent-area mb-45">
        {/* <!-- Section Tittle --> */}
        <div className="small-tittle mb-2">
          <h4>Mới nhất</h4>
        </div>
        {/* <!-- Details --> */}
        <div className="most-recent mb-4">
          <div className="most-recent-img">
            <Image
              src={BASE_API + firstItem?.thumbnail.data.attributes.url}
              alt={firstItem.title}
              className=""
              // objectFit="cover"
              width={100}
              height={100}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <div className="most-recent-cap">
              <span className="bgbeg">
                {firstItem.tags.data[0].attributes.title}
              </span>
              <h4>
                <Link href={`/news/${firstItem.slug}`}>{firstItem.title}</Link>
              </h4>
              <p>
                {firstItem.author} |{" "}
                {dayjs(firstItem.created_date).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </div>
        {/* <!-- Single --> */}
        {data.slice(1).map((e: any, i: number) => (
          <div className="most-recent-single" key={i}>
            <div className="most-recent-images">
              <Image
                src={BASE_API + e.attributes.thumbnail.data.attributes.url}
                alt={e.attributes.title}
                width={85}
                height={79}
              />
            </div>
            <div className="most-recent-capt w-[70%]">
              <h4>
                <Link
                  href={`/news/${e.attributes.slug}`}
                  className="animated animatedFadeInUp fadeInUp"
                >
                  {e.attributes.title}
                </Link>
              </h4>
              <p>
                {e.attributes.author} |{" "}
                {dayjs(e.attributes.created_date).format("DD/MM/YYYY HH:mm")}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="single-follow mb-40">
        <FormMail />
      </div>
    </div>
  );
};

export default TabContentRight;

"use client";

/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./styles.css";
import Image from "next/image";
import { BASE_API } from "@/config/site";
import Link from "next/link";
import dayjs from "dayjs";
import { useAppContext } from "@/context/app-context";

interface Weekly2NewsAreaProps {
  mostPopular: any;
}

const Weekly2NewsArea = ({ mostPopular }: Weekly2NewsAreaProps) => {
  const sliderRef = useRef<Slider | null>(null);
  const { banner } = useAppContext();

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    const checkBreakpoint = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setBreakpoint("sm");
      } else setBreakpoint("md");
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);

    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: breakpoint === "sm" ? 1 : 3,
    slidesToScroll: breakpoint === "sm" ? 1 : 3,
    autoplay: true,
    autoplaySpeed: 5000,
    rows: 1,
  };
  return (
    <>
      <div className="weekly2-news-area pt-15 pb-13 gray-bg my-6">
        <div className="container">
          <div className="weekly2-wrapper">
            <div className="row">
              {/* <!-- Banner --> */}
              <div className="col-lg-3">
                <div className="home-banner2 d-none d-lg-block">
                  {banner && banner?.data.length >= 2 && (
                    <Link
                      href={banner?.data[1].attributes.link}
                      passHref
                      legacyBehavior
                    >
                      <a target="_blank" rel="noopener noreferrer">
                        <Image
                          src={
                            BASE_API +
                            banner?.data[1].attributes.image.data.attributes.url
                          }
                          alt="header-banner"
                          className=""
                          width={100}
                          height={100}
                          sizes="100vw"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-lg-9">
                <div className="slider-wrapper">
                  {/* <!-- section Tittle --> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="small-tittle mb-30">
                        <h4>Phổ biến nhất</h4>

                        <div className="slider-btn">
                          <span
                            className="slick-prev-slick-arrow"
                            onClick={previous}
                          >
                            <i className="fa fa-angle-left"></i>
                          </span>
                          <span
                            className="slick-prev-slick-arrow"
                            onClick={next}
                          >
                            <i className="fa fa-angle-right"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Slider --> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="slider-container ">
                        <Slider {...settings} ref={sliderRef}>
                          {mostPopular &&
                            mostPopular.data.map((e: any, i: number) => (
                              <div className="weekly2-single px-2" key={i}>
                                <div className="weekly2-img">
                                  <Image
                                    src={
                                      BASE_API +
                                      e.attributes.thumbnail.data.attributes.url
                                    }
                                    alt={e.attributes.title}
                                    className="mx-auto"
                                    // objectFit="cover"
                                    width={100}
                                    height={100}
                                    sizes="100vw"
                                    style={{
                                      width: "100%",
                                      height:
                                        breakpoint === "md" ? "165px" : "auto",
                                    }}
                                  />
                                </div>
                                <div className="weekly2-caption">
                                  <h4>
                                    <Link href={`/news/${e.attributes.slug}`}>
                                      {e.attributes.title}
                                    </Link>
                                  </h4>
                                  <p>
                                    {e.attributes.author} |{" "}
                                    {dayjs(e.attributes.created_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Weekly2NewsArea;

import { BASE_API } from "@/config/site";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Skeleton from "react-loading-skeleton";

interface PropsTabContentLeft {
  loading: boolean;
  active: string;

  postsByCategory: any;
}

const TabContentLeft = ({
  postsByCategory,
  loading,
  active,
}: PropsTabContentLeft) => {
  if (!postsByCategory || postsByCategory.data.length === 0) {
    return <></>;
  }

  const { data } = postsByCategory;
  const firstItem = data[0].attributes;
  return (
    <div className="row">
      <div className="col-12">
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                {!loading ? (
                  <div className="whats-news-single mb-40">
                    <div className="whates-img">
                      <Image
                        src={
                          BASE_API + firstItem?.thumbnail.data.attributes.url
                        }
                        alt={firstItem.title}
                        className=""
                        // objectFit="cover"
                        width={100}
                        height={100}
                        sizes="100vw"
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="whates-caption">
                      <h4>
                        <Link href={`/news/${firstItem.slug}`}>
                          {firstItem.title}
                        </Link>
                      </h4>
                      <span>
                        by {firstItem.author} -{" "}
                        {dayjs(firstItem.created_date).format("DD/MM/YYYY")}
                      </span>
                      <p>{firstItem?.prewview}</p>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-1 overflow-hidden px-1 py-2">
                    <Skeleton className="h-4 w-10 rounded" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-8 rounded-sm" />
                  </div>
                )}
              </div>
              {/* <!-- Right single caption --> */}
              <div className="col-xl-6 col-lg-12">
                {loading ? (
                  <div className="space-y-1 overflow-hidden px-1 py-2">
                    <Skeleton className="h-4 w-10 rounded" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-4 w-10 rounded" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-4 w-10 rounded" />
                    <Skeleton className="h-8 rounded-sm" />
                    <Skeleton className="h-8 rounded-sm" />
                  </div>
                ) : (
                  <div className="row">
                    {data.slice(1).map((e: any, i: number) => (
                      <div
                        className="col-xl-12 col-lg-6 col-md-6 col-sm-10"
                        key={i}
                      >
                        <div className="whats-right-single my-2 pb-2 border-b-2">
                          <div className="whats-right-img w-[124px] h-[104px] flex items-center">
                            <Image
                              src={
                                BASE_API +
                                e?.attributes.thumbnail.data.attributes.url
                              }
                              alt={e?.attributes.title}
                              className=""
                              width={100}
                              height={100}
                              sizes="100vw"
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                              }}
                              // fill
                            />
                          </div>
                          <div className="whats-right-cap w-[70%] pl-2">
                            <span className="colorb">
                              {
                                e?.attributes.tags.data.find(
                                  (el: any) => el.attributes.title === active
                                )?.attributes?.title
                              }
                            </span>
                            <h4>
                              <Link href={`/news/${e?.attributes.slug}`}>
                                {e?.attributes.title}
                              </Link>
                            </h4>
                            <p>
                              {dayjs(e?.attributes.created_date).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabContentLeft;

"use client";
import React, { useCallback, useEffect, useState } from "react";
import TabContentLeft from "../tab-content-left/tab-content-left";
import TabContentRight from "../tab-content-right/tab-content-right";
import WnNavButton from "../wn-nav-button/wn-nav-button";
import { useAppContext } from "@/context/app-context";
import API from "@/lib/Api";
import { BASE_API } from "@/config/site";
import Image from "next/image";
import Link from "next/link";

interface LatestProps {
  mostRecent: any;
}

const LatestArea = ({ mostRecent }: LatestProps) => {
  const [active, setActive] = useState("");
  const [postsByCategory, setPostsByCategory] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { dataTags } = useAppContext();

  const handleGetMostRecent = useCallback(async (tag: string) => {
    try {
      setLoading(true);
      const { data } = await API.get(
        `${BASE_API}/api/posts/?filters[tags][title][$eq]=${tag}&pagination[limit]=5&populate=*`
      );
      if (data) {
        setPostsByCategory(data);
      }
    } catch (error) {
      setPostsByCategory(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    active && handleGetMostRecent(active);
  }, [active, handleGetMostRecent]);

  useEffect(() => {
    dataTags && setActive(dataTags.data[0].attributes.title);
  }, [dataTags]);

  return (
    <section className="whats-news-area pt-10 pb-10 gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="whats-news-wrapper">
              <div className="mb-15">
                <div className="properties__button">
                  <nav>
                    <div
                      className="nav nav-tabs"
                      id="nav-tab"
                      role="tablist"
                      style={{
                        justifyContent: "start",
                      }}
                    >
                      {dataTags &&
                        dataTags.data.map((e: any, index: number) => (
                          <WnNavButton
                            idActive={active}
                            text={e.attributes.title}
                            key={index}
                            setActive={setActive}
                          />
                        ))}
                    </div>
                  </nav>
                </div>
              </div>
              <TabContentLeft
                active={active}
                loading={loading}
                postsByCategory={postsByCategory}
              />
            </div>
            <div className="banner-one mt-2 mb-3">
              <Link
                href={"https://vientinchicarbon.com/"}
                passHref
                legacyBehavior
              >
                <a target="_blank" rel="noopener noreferrer">
                  <Image
                    src="/img/gallery/body_card1.png"
                    alt="baner"
                    width={100}
                    height={100}
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </a>
              </Link>
            </div>
          </div>
          <TabContentRight dataPost={mostRecent} />
        </div>
      </div>
    </section>
  );
};

export default LatestArea;
